
import './App.css';





function App() {
  return (
    
    <div className="main">

      <div className="header">
        <div className="logo">
          S|Y
        </div>
        <button className="enableEthereumButton ui-btn ui-shadow ui-corner-all" data-role="button">Connect To Wallet</button>
        <div className="connect_message">You must be connected to Polygon Network</div>
      </div>
      <div className="page">
        <div className="data_log">
            <div className='close_button'>
              ClOSE LOG
            </div>
        </div>
        <div className='get_started_column'>
          <div className="get_started">
            Have you ever noticed how no two exchanges ever seem to have the same price for tokens? Now you can take advantage of that difference and buy any token on one exchange and immediately sell it to another exchange and extracting the profit. SwapYard wraps all your transactions in a flash loan of your choosing. SwapYard automatically detects when a trade will not be profitable BEFORE it completes processing on the blockchain. If this happens, you only need to pay for gas. Otherwise, you just found free money! Conragulations! If you haven't tried already, what are you waiting for?
            <ul>
              <li>Select a token to start with...</li>
              <li>Find a place to buy...</li>
              <li>Choose a place to sell...</li>
              <li>Send a test transaction...</li>
              <li>Execute a trade...</li>
              <li>Keep the profit...</li>
            </ul>
            <button className="continue_button">
              CONTINUE
            </button>
          </div>
        </div>
        <div className='edit_column'>
          <form>
            <label>Loan Token Address:</label>
            <select id="loanTokenAddress">
              <option value='0x2791bca1f2de4661ed88a30c99a7a9449aa84174' defaultValue='0x2791bca1f2de4661ed88a30c99a7a9449aa84174' >USDC</option>  
              <option value='0xc2132D05D31c914a87C6611C10748AEb04B58e8F' defaultValue='0xc2132D05D31c914a87C6611C10748AEb04B58e8F' >USDT</option> 
              <option value='0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270' defaultValue='0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270' >wMATIC</option> 
              <option value='0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063' defaultValue='0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063' >DAI</option> 
              <option value='0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619' defaultValue='0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619' >WETH</option> 
            </select>  
            <label>Loan Pair Token Address:</label>
            <select id="loanPoolPairToken">
              <option value='0x2791bca1f2de4661ed88a30c99a7a9449aa84174' defaultValue='0x2791bca1f2de4661ed88a30c99a7a9449aa84174' >USDC</option>  
              <option value='0xc2132D05D31c914a87C6611C10748AEb04B58e8F' defaultValue='0xc2132D05D31c914a87C6611C10748AEb04B58e8F' >USDT</option> 
              <option value='0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270' defaultValue='0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270' >wMATIC</option> 
              <option value='0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063' defaultValue='0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063' >DAI</option> 
              <option value='0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619' defaultValue='0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619' >WETH</option> 
            </select> 
            <label>DODO Factory:</label>
            <select id="DODOFactory">
              <option value='0xd24153244066F0afA9415563bFC7Ba248bfB7a51' defaultValue='0xd24153244066F0afA9415563bFC7Ba248bfB7a51' >DODO Private Pool Factory</option>  
              <option value='0x79887f65f83bdf15Bcc8736b5e5BcDB48fb8fE13' defaultValue='0x79887f65f83bdf15Bcc8736b5e5BcDB48fb8fE13' >DODO Vending Machine Factory</option> 
              <option value='0x43C49f8DD240e1545F147211Ec9f917376Ac1e87' defaultValue='0x43C49f8DD240e1545F147211Ec9f917376Ac1e87' >DODO Stable Pool Factory</option> 
            </select> 
            <div className='save_button'>
              SAVE CHANGES
            </div>
            <div className='reset_button'>
              RESET SWAPS
            </div>
            <div className='log_button'>
              VIEW LOGS
            </div>
          </form>
        </div>
        <div className="swaps_column">
          <div className="swaps_heading">
            First choose a coin to swap...
          </div>

          <div className='swaps'>
            
          </div>  

        </div>

        <div className='buys_column'>
          <div className='buys_heading'>
            Now choose where to buy... 
          </div>

          <div className='buys'>
            
          </div>

          <div className='reset_button'>
            RESET SWAPS
          </div>
          
        </div>

        <div className='sells_column'>
          <div className='sells_heading'>
            Next choose a place to sell...
          </div>

          <div className='sells'>

          </div>

          <div className='reset_button'>
            RESET SWAPS
          </div>

        </div>

        <div className='trade_column'>
          <div className='borrow_heading'>
              Borrow money and trade...
          </div>

          <div className='borrow_select'>
              <div className='borrow_amount'>
                
              </div>
              <input type="range" name="slider-1" id="slider-1" min="0" step="1" max="1000" ></input>
          </div>
            
          <div className='selected_cards'>
            <div className="swap_card">
              No Coin Selected...
            </div>
            <div className="buy_card">
              No Buy Exchange Selected...
            </div>
            <div className="sell_card">
              No Sell Exchange Selected...
            </div>
          </div>
          <div className='loading'>
            <img src="../img/loading.svg" alt=''></img>
          </div>
          <div className='trade_info'>
            <div className='trade_details reserves_delta'>
                <div className='label_left'>
                  RERSERVE DELTA
                </div>
                <div className='label_right'>
                  000
                </div>
            </div>
            <div className='trade_details buy_return'>
                <div className='label_left'>
                  BUY AMOUNT
                </div>
                <div className='label_right'>
                  000
                </div>
            </div>
            <div className='trade_details sell_return'>
                <div className='label_left'>
                  SELL RETURN
                </div>
                <div className='label_right'>
                  000
                </div>
            </div>
            <div className='trade_details estimated_return'>
                <div className='label_left'>
                  EST RETURN
                </div>
                <div className='label_right'>
                  000   
                </div>
            </div>
            <div className='trade_details slippage_allowance'>
                <div className='label_left'>
                  SLIPPAGE ALLOWANCE
                </div>
                <div className='label_right'>
                  000
                </div>
            </div>
            <div className='trade_details estimated_gas'>
                <div className='label_left'>
                  EST GAS
                </div>
                <div className='label_right'>
                  000
                </div>
            </div>
          </div>
          <div className='test_button'>
              TEST
          </div>

          <div className='confirmation_button'>
              SEND
          </div>
          
          <div className='reset_button'>
            RESET SWAPS
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
